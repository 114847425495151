import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyDGuBvMA06cnLvgM2qaOJdie9FbDbUXld8',
  authDomain: 'spicefactory-website.firebaseapp.com',
  projectId: 'spicefactory-website',
  storageBucket: 'spicefactory-website.appspot.com',
  messagingSenderId: '6377097497',
  appId: '1:6377097497:web:27800eb598ae1bfca1dd88',
};

firebase.initializeApp(config);

const firebaseStorage = firebase.storage();
const firestore = firebase.firestore();

export { firestore, firebaseStorage };
