import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { JumboButton } from '../../components/JumboButton/JumboButton';
import { firebaseStorage, firestore } from '../../middleware/firestore/firestore';
import '../../css/main.scss';

interface JobApplicationData {
  name: string;
  email: string;
  cv: string;
  jobID: string;
  jobTitle: string;
  linkedin?: string;
  salary?: number;
  startDate?: Date;
  introMessage?: string;
  applicantStatus?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      // margin: theme.spacing(5),
      width: '100%',
    },
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: '"rgba(0,0,0,0.5)"',
    zIndex: theme.zIndex.drawer + 1,
  },
  gridItem: {
    marginBottom: '8px',
    marginTop: '8px',
    padding: '0',
  },
  fileDialog: {
    padding: '15px',
    border: 'none',
    background: 'transparent',
    color: 'black',
    fontSize: '30px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  formInput: {
    display: 'block',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 0,
    [`& fieldset`]: {
      borderRadius: 0,
      // borderColor: theme.palette.,
    },
    color: theme.palette.text.primary,
    width: '100%',
  },
  dragZone: {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: '1px dashed #000',
    padding: '10px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: 1,
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid black',
    },
    '&:focus': {
      outline: '#888',
    },
  },
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    borderBottom: '1px solid rgba(53, 40, 28, 0.2)',
  },
}));

const JobApplicationForm = ({ data }) => {
  let job = data.firestoreJobs;

  const classes = useStyles();
  const [file, setFile] = useState(undefined);
  const [fields, setFields] = useState<JobApplicationData>();
  const [desiredStartDate, setDesiredStartDate] = useState<Date>(null);
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const [openBackdrop, setBackdrop] = useState<boolean>(false);
  const [toggleAlert, setAlert] = useState<boolean>(false);
  const [toggleDialog, setDialog] = useState<boolean>(false);
  const storageRef = firebaseStorage.ref();
  const thankYouRef = useRef<HTMLInputElement | undefined>(undefined);

  useEffect(() => {
    if (toggleForm) {
      thankYouRef.current.scrollIntoView({ behavior: 'smooth' });
      window && window.scroll(0, window.scrollY - 150);
    }
  }, [toggleForm]);

  const handleDialogClose = () => {
    setDialog(false);
  };

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
    setAlert(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: '.pdf',
    maxFiles: 1,
    multiple: false,
    noClick: true,
  });

  const handleChange = e => {
    setFields(old => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (file) {
      setBackdrop(!openBackdrop);
      uploadCV();
    } else {
      setAlert(!toggleAlert);
    }
  };

  const uploadCV = useCallback(() => {
    const storageApplicationRef = storageRef
      .child(`applications/${fields.email}/${file.name}` + ' - ' + Date.now())
      .put(file);
    storageApplicationRef.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      error => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            setDialog(!toggleDialog);
            break;
          case 'storage/canceled':
            setDialog(!toggleDialog);
            break;
          case 'storage/unknown':
            setDialog(!toggleDialog);
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        storageApplicationRef.snapshot.ref.getDownloadURL().then(downloadURL => {
          completeSubmit(downloadURL);
        });
      }
    );
  }, [fields, file, desiredStartDate]);

  const completeSubmit = url => {
    const batch = firestore.batch();
    const formData = {
      ...fields,
      appliedAt: new Date(),
      cv: url,
      jobId: job.id,
      jobTitle: job.title,
      category: job.category,
      startDate: desiredStartDate,
      applicantStatus: 'NEW',
    };
    batch.set(firestore.collection('applicants').doc(fields.email), formData, { merge: true });
    batch.set(firestore.collection('applicants').doc(fields.email).collection('applications').doc(), formData);
    batch
      .commit()
      .then(() => {
        setToggleForm(!toggleForm);
        setBackdrop(false);
      })
      .catch(_ => {
        setDialog(!toggleDialog);
      });
  };

  return (
    <div style={{ maxWidth: '700px', display: 'block', margin: 'auto' }}>
      <Snackbar open={toggleAlert} autoHideDuration={5000}>
        <Alert severity="warning" style={{ width: '100%' }}>
          No file selected!
        </Alert>
      </Snackbar>
      <Backdrop style={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 || 'auto' }} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {toggleDialog && (
        <Dialog
          open={toggleDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Error: File upload error.'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ margin: '0', fontSize: '20px' }}>Oops something went wrong... Please try again. </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {!toggleForm ? (
        <form id="apply-form" onSubmit={handleSubmit}>
          <div className="post-content">
            <h2 style={{ fontSize: '4rem', margin: '1em 0 0' }}>Like what you see?</h2>
            <p style={{ marginTop: '0' }}>Fill in this form below and we'll get back at you as soon as possible!</p>
          </div>
          <div className={classes.fieldGroup}>
            <TextField
              fullWidth
              required
              type="text"
              name="name"
              label="Your name"
              className={classes.formInput}
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              type="email"
              name="email"
              label="Email"
              className={classes.formInput}
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              multiline={true}
              minRows={10}
              type="text"
              name="introMessage"
              label="Tell us a bit more why you want to work with us..."
              className={classes.formInput}
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          <div className={classes.fieldGroup}>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <div className={classes.dragZone} {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography>Files dropped</Typography>
                ) : (
                  <Typography>Drag and drop file here or</Typography>
                )}
                <button className={classes.fileDialog} type="button" onClick={open}>
                  Browse
                </button>
              </div>
            </div>
            <div>
              <p style={{ color: '#707070' }}>Please upload .pdf file</p>
              {toggleAlert && (
                <Alert style={{ display: 'flex', alignContent: 'center' }} severity="warning">
                  No file selected!
                </Alert>
              )}
            </div>
            <aside>
              {file != null && (
                <div style={{ display: 'block' }}>
                  <p style={{ margin: '0' }}>Files:</p>
                  <ul style={{ paddingLeft: '0', marginTop: '0' }}>&#8226; {file.name}</ul>
                </div>
              )}
            </aside>
          </div>
          <div className={classes.fieldGroup}>
            <TextField
              fullWidth
              type="text"
              name="linkedIn"
              label="LinkedIn Profile, Website, Blog or Portfolio (optional)"
              className={classes.formInput}
              variant="outlined"
              onChange={handleChange}
            />
            {/*<LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Desired Start Date (optional)"
                openTo="day"
                views={['year', 'month', 'day']}
                minDate={new Date()}
                value={desiredStartDate}
                inputFormat="dd/MM/yyyy"
                onChange={newDate => {
                  setDesiredStartDate(newDate);
                }}
                renderInput={params => <TextField className={classes.formInput} fullWidth {...params} />}
              />
            </LocalizationProvider>
            <FormControl fullWidth className={classes.formInput}>
              <InputLabel htmlFor="outlined-adornment-amount">Desired Monthly Compensation (optional)</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                name="salary"
                type="number"
                fullWidth
                onChange={handleChange}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Desired Monthly Compensation (optional)"
              />
            </FormControl>*/}
          </div>
          <JumboButton color="primary" variant="contained" type="submit">
            Apply now
          </JumboButton>
        </form>
      ) : (
        <div ref={thankYouRef} style={{ fontSize: '3rem', padding: '50px 0 50px 0' }}>
          <p>Thank you for applying! We will review the submission and get in touch with you with the next steps.</p>
        </div>
      )}
    </div>
  );
};
export default JobApplicationForm;
