import * as React from 'react';
import { Container } from '@material-ui/core';
import Layout from '../layout';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { JumboButton } from '../components/JumboButton/JumboButton';
import JobApplicationForm from '../components/JobApplicationForm/JobApplicationForm';
import '../css/main.scss';

const Job = ({ data }) => {
  let job;
  let jobImage;
  job = data.firestoreJobs;
  jobImage = data.firestoreJobs.heroImage?.pop();

  return (
    <Layout>
      <Container maxWidth="xl" style={{ paddingBottom: '50px' }}>
        <Helmet>
          <title>{`${job.title} `}</title>
          {/*meta TItle*/}
        </Helmet>
        <div>
          <div className="legacy">
            <h1 style={{ marginTop: '16rem', marginBottom: '0' }} className="title">
              {job.title}
            </h1>
            <p style={{ fontSize: '1.5em', marginTop: '8px', marginBottom: '3rem' }}>Join our team</p>
            <JumboButton className="jumboButton" color="primary" variant="contained" href="#apply-form">
              Apply Now
            </JumboButton>
            {jobImage && (
              <img
                src={jobImage.downloadURL}
                style={{ width: '100%', height: 'auto', marginBottom: '3rem' }}
                alt={job.title}
              />
            )}
            <div className="post-content" dangerouslySetInnerHTML={{ __html: `${job.description}` }} />
          </div>
          <JobApplicationForm data={data} />
        </div>
      </Container>
    </Layout>
  );
};

export default Job;

export const pageQuery = graphql`
  query JobPostBySlug($id: String) {
    firestoreJobs(id: { eq: $id }) {
      description
      fields {
        slug
      }
      id
      title
      category
    }
  }
`;

//heroImage {
//   downloadURL
// }
